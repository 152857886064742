<template>
  <div>
    <transition name="filter-fade">
      <div class="filter-bg" v-if="visible" @click="handleClose" />
    </transition>
    <transition name="filter-slide">
      <div class="filter-wrap" v-if="visible">
        <div class="filter-close" @click="handleClose">
          <i class="iconfont icon-class-right" />
        </div>
        <div class="filter-body">
          <div class="filter-shadow">
            <div class="title">
              <div class="title-type">
                <template v-if="typeInfo.type.length < 5">{{
                  typeInfo.type
                }}</template>
                <template v-else>
                  <div>{{ typeInfo.type.slice(0, 2) }}</div>
                  <div>{{typeInfo.type.slice(2)}}</div>
                </template>
              </div>
              <div class="title-type-name">{{ typeInfo.h1 }}</div>
              <div class="title-ai" v-if="aiTypeList.includes(typeInfo.typeId)">
                AI
              </div>
              <div class="title-search">
                <el-input
                  v-model="keyword"
                  class="search-input"
                  :class="{ active: focusInput }"
                  @keyup.enter.native="handleSearch"
                  @clear="handleSearch"
                  @focus="focusInput = true"
                  @blur="focusInput = !!keyword"
                  clearable
                  :placeholder="$t('search.tip')"
                >
                  <i
                    v-if="!focusInput"
                    slot="prefix"
                    @click="focusInput = true"
                    class="el-input__icon el-icon-search"
                  ></i>
                  <i
                    v-else
                    @click="handleSearch"
                    slot="prefix"
                    class="el-input__icon el-icon-search"
                    :class="{ active: focusInput }"
                  />
                </el-input>
              </div>
            </div>
            <div class="filter">
              <div class="tag-filter">
                <div class="tag-filter-icon">
                  <i class="iconfont icon-menu"></i>
                </div>
                <div
                  class="filter-btn"
                  :class="{ active: cFilter['label_id'] === item.id }"
                  v-for="(item, index) of tagFilterList"
                  :key="`${item.id}_${index}`"
                  @click="handleFilter('label_id', item.id)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="select-filter-hr"></div>
              <div class="select-filter">
                <el-select
                  v-for="item of filtersList"
                  :key="item.type"
                  :placeholder="item.name"
                  v-model="cFilter[item.type]"
                  @change="(value) => handleFilter(item.type, value)"
                  v-if="item.list.length > 1"
                >
                  <el-option
                    v-for="citem of item.list"
                    :key="citem.id"
                    :label="citem.name"
                    :value="citem.id"
                  >
                    {{ citem.label || citem.name }}
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="subtitle">
              {{ i18n.Pra.text123 }}<b>{{ typeTotal }}</b
              >{{ i18n.Pra.text124 }}<b>{{ pagination.total }}</b
              >{{ i18n.Pra.topic
              }}<template v-if="filterText">
                {{ i18n.Pra.text125 }}<b>{{ filterText }}</b>
              </template>
            </div>
          </div>
          <div v-if="!loading" class="loading-content">
            <template v-if="list && list.length">
              <ul class="list">
                <li v-for="(item, index) of list" :key="index">
                  <div
                    style="display: flex;align-items: center; flex: 1;cursor: pointer"
                    @click="selectItem('select', item)"
                  >
                    <span
                      class="name"
                      :class="activeID === item.gre_id ? 'activeClass' : ''"
                      :title="item.title"
                      >{{ index + 1 + pagination.before }}.{{
                        item.title
                      }}</span
                    >
                    <span class="idTag">#{{ item.gre_id }}</span>
                    <span class="tagList">
                      <span
                        class="tagItem"
                        v-for="tagItem of item.tag"
                        :key="`tagItem-${tagItem}`"
                        :style="{
                          background:
                            colorMap[tagItem] && colorMap[tagItem].bgColor,
                          color: colorMap[tagItem] && colorMap[tagItem].color
                        }"
                      >
                        {{ tagItem }}
                      </span>

                      <span
                        class="more"
                        v-if="item.moreTag.length && !item.showMore"
                        @click="handleMoreTag(index)"
                        >···</span
                      >

                      <span
                        v-else
                        class="tagItem"
                        v-for="moreItem of item.moreTag"
                        :key="`moreItem-${moreItem}`"
                        :style="{
                          background:
                            colorMap[moreItem] && colorMap[moreItem].bgColor,
                          color: colorMap[moreItem] && colorMap[moreItem].color
                        }"
                      >
                        {{ moreItem }}
                      </span>
                    </span>
                  </div>

                  <div class="right-tag">
                    <span class="detailTag" v-if="item.has_detail"
                      ><i class="f-icon icon-Videocamera" />{{
                        i18n.Pra.text126
                      }}</span
                    >
                    <span class="idTag time">{{ item.try_tips }}</span>
                  </div>

                  <div class="collection">
                    <i
                      @click="handleCollection(item)"
                      class="iconfont icon-star-c"
                      :class="{
                        'icon-star': item.is_liked == 0,
                        'icon-star-c': item.is_liked == 1,
                        actived: item.is_liked == 1
                      }"
                    />
                  </div>
                </li>
              </ul>

              <div class="pagination-wrap">
                <div class="tip-text">小贴士：{{ tipText }}</div>
                <el-pagination
                  small
                  layout="prev, pager, next"
                  class="exercises-list-pagination"
                  @current-change="fetchList"
                  :total="pagination.total"
                  :page-size="pagination.pageSize"
                  :current-page.sync="pagination.currentPage"
                >
                </el-pagination>
              </div>
            </template>
            <Empty v-else />
          </div>
          <div v-else>
            <Loading />
          </div>
        </div>
      </div>
    </transition>

    <div v-if="!visible" class="filter-open-btn" @click="handleClose">
      <i class="iconfont icon-class-right"></i>
    </div>

    <CollectDialog
      :current-id="
        activeItem.is_gre == 2 ? activeItem.question_id : activeItem.gre_id
      "
      :is-practice="activeItem.is_gre == 2"
      :detail="activeItem"
      @onRefresh="fetchList"
      ref="collect"
    />
  </div>
</template>

<script>
import Empty from '@/components/ExercisesEmpty.vue'
import Loading from '@/components/loading'
import { apiGreList, apiMyCollectCategory } from '@/api/api.js'
import { TAG_COLOR_MAP } from '@/database/tagColor'
import CollectDialog from '@/components/ExercisesContent/collectDialog'
import i18n from '@/i18n/i18n'

const FILTERS_LIST = [
  {
    type: 'order_by',
    name: i18n.locale === 'ch' ? '按新题排序' : 'Number of exercises',
    list: [
      {
        name: i18n.locale === 'ch' ? '按新题排序' : 'According to New topic',
        id: 'id'
      },
      {
        name:
          i18n.locale === 'ch' ? '按高频排序' : 'According to High frequency',
        id: 'gp'
      }
    ]
  },
  {
    type: 'view_cnt',
    name: i18n.locale === 'ch' ? '练习次数' : 'Number of exercises',
    list: [
      {
        label: i18n.locale === 'ch' ? '不限' : 'Unlimited',
        name: i18n.locale === 'ch' ? '练习次数' : 'Unlimited',
        id: 0
      },
      { name: i18n.locale === 'ch' ? '未练习' : 'Not practiced', id: 1 },
      { name: i18n.locale === 'ch' ? '低于2次' : 'Less than 2 times', id: 2 },
      { name: i18n.locale === 'ch' ? '低于5次' : 'Less than 5 times', id: 5 }
    ]
  },
  {
    type: 'cate_tag',
    name: i18n.locale === 'ch' ? '题目类型' : 'Topic type',
    list: []
  },
  {
    type: 'label_id_2',
    name: i18n.locale === 'ch' ? '题目标签' : 'Tag',
    list: [
      {
        label: i18n.locale === 'ch' ? '不限' : 'Unlimited',
        name: i18n.locale === 'ch' ? '题目标签' : 'Unlimited',
        id: 0
      }
    ],
    excludes: [
      i18n.locale === 'ch' ? '高频' : 'High frequency',
      i18n.locale === 'ch' ? '机经题' : 'Machine Sutra questions',
      i18n.locale === 'ch' ? '新题' : 'New topic',
      i18n.locale === 'ch' ? '模拟题' : 'Simulation problem'
    ]
  },
  {
    type: 'like_cate_id',
    name: i18n.locale === 'ch' ? '收藏' : 'Collection',
    list: [
      {
        label: i18n.locale === 'ch' ? '不限' : 'Unlimited',
        name: i18n.locale === 'ch' ? '收藏' : 'Unlimited',
        id: 0
      }
    ]
  }
]
const DEFAULT_TIP =
  i18n.locale === 'ch'
    ? '首次复习请优先准备本月预测题哦。'
    : 'For the first review, please give priority to preparing the prediction questions of this month.'

export default {
  components: {
    Empty,
    Loading,
    CollectDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {}
    },
    realFilters: {
      type: Object,
      default: () => {}
    },
    cateId: {
      type: [String, Number]
    },
    typeInfo: {
      type: Object
    },
    typeTotal: {
      type: Number
    },
    activeID: {
      type: Number
    },
    curGreId: {
      type: Number,
      default: 0
    },
    filterText: {
      type: String
    },
    tagFilterList: {
      type: Array,
      default: []
    },
    tagList: {
      type: Array,
      default: []
    },
    cateTagList: {
      type: Array,
      default: []
    },
    onSearch: {
      type: Function,
      defualt: () => {}
    }
  },
  data() {
    return {
      list: [],
      colorMap: TAG_COLOR_MAP,
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 15,
        before: 0
      },
      loading: false,

      localCurGreId: 0,

      cFilter: {},

      collectionCate: [],

      aiTypeList: [
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        42,
        43,
        44,
        52,
        53
      ],

      activeItem: {},
      focusInput: false,
      keyword: ''
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
    filtersList() {
      return FILTERS_LIST.map((item) => {
        if (item.type === 'label_id_2') {
          return {
            ...item,
            list: item.list.concat(
              this.tagList
                .filter((m) => !item.excludes.includes(m.name))
                .map((item) => ({
                  ...item,
                  name: `仅看${item.name}`
                }))
            )
          }
        }
        // if (item.type === 'order_by') {
        //   return {
        //     ...item,
        //     list: item.list.concat(
        //       this.tagList
        //         .filter((m) => item.includes.includes(m.name))
        //         .map((m) => ({
        //           ...m,
        //           name:
        //             i18n.locale === 'ch'
        //               ? `按${m.name}排序`
        //               : `According to ${m.name}`
        //         }))
        //     )
        //   }
        // }
        if (item.type === 'cate_tag') {
          return {
            ...item,
            list: [...item.list, ...this.cateTagList].map((item) => {
              if (item.name === '不限') {
                return {
                  ...item,
                  label: i18n.locale === 'ch' ? '不限' : 'Unlimited',
                  name: i18n.locale === 'ch' ? '题目类型' : 'Topic type'
                }
              }
              return item
            })
          }
        }
        if (item.type === 'like_cate_id') {
          return {
            ...item,
            list: item.list.concat(this.collectionCate)
          }
        }
        return {
          ...item
        }
      })
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible) {
          document.body.style.overflowY = 'hidden'

          this.cFilter = {
            ...this.filters
          }
          // 打开列表默认查询 x月高频
          if(this.cFilter['label_id'] == 0){
            this.cFilter['label_id'] = 39
          }
          this.tipText = DEFAULT_TIP

          this.fetchCollectionCate()

          this.fetchList()
        } else {
          document.body.style.overflowY = 'auto'
        }
      }
    },
    curGreId: {
      immediate: true,
      handler() {
        if (this.localCurGreId !== this.curGreId) {
          this.localCurGreId = this.curGreId
        }
      }
    },
    'pagination.currentPage': function() {
      this.localCurGreId = 0
    }
  },
  methods: {
    refresh(curGreId) {
      this.cFilter = {
        ...this.cFilter,
        ...this.filters
      }

      this.localCurGreId = curGreId || this.localCurGreId
      this.pagination.currentPage = 1
      this.fetchList()
    },
    async fetchCollectionCate() {
      const { data } = await apiMyCollectCategory()
      this.collectionCate = data
    },
    fetchList() {
      if (this.loading) {
        return
      }

      this.loading = true
      apiGreList({
        cate_id: this.cateId,
        page: this.pagination.currentPage,
        page_size: this.pagination.pageSize,
        cur_gre_id: this.localCurGreId,
        key_word: this.keyword,
        ...this.cFilter
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          this.list = res.data.list_item.map((item) => {
            return {
              ...item,
              tag: item.tag_list.slice(0, 6),
              moreTag: item.tag_list.slice(6),
              showMore: false
            }
          })

          this.pagination = {
            ...this.pagination,
            total: res.data.list_selected,
            currentPage: res.data.pager.page,
            before: this.pagination.pageSize * (res.data.pager.page - 1)
          }

          this.listResult(this.activeID)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleMoreTag(index) {
      this.list[index].showMore = true
    },
    listResult(activeID) {
      const findIndex = this.list.findIndex((m) => m.gre_id === activeID)
      this.$emit('listResult', {
        list: this.list,
        index: findIndex > -1 ? this.pagination.before + 1 + findIndex : null
      })
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    selectItem(type, item) {
      this.listResult(item.gre_id)

      this.$emit('selectItem', type, item)
      this.handleClose()
    },
    handleFilter(type, id) {
      this.isChange = true

      this.cFilter = {
        ...this.cFilter,
        [type]: id,
        is_gre: 0,
        is_like: 0
      }

      // 提示显示逻辑
      switch (type) {
        case 'is_gre':
          {
            if (id === 2) {
              this.tipText = '"文本回忆"也属于真题，也是备考必看哦！'
            } else {
              this.tipText = DEFAULT_TIP
            }
          }
          break
        case 'label_id_2':
          {
            const tagItem = this.tagList.find((m) => m.id === id) || {}
            if (tagItem.name === '新增高频') {
              this.tipText = '"新增高频"是相较上月预测新增的高频题目。'
            } else if (tagItem.name === '老题新考') {
              this.tipText =
                '"老题新考"是原真题题库中重新成为本月预测的高频题目。'
            } else {
              this.tipText = DEFAULT_TIP
            }
          }
          break
        default: {
          this.tipText = DEFAULT_TIP
        }
      }

      this.$emit('update:realFilters', {
        ...this.cFilter
      })

      this.onSearch(undefined, this.onSuccess)
      this.fetchList()
    },

    handleSearch() {
      console.log('=======')
      this.pagination.currentPage = 1

      this.fetchList()
    },
    handleCollection(item) {
      this.activeItem = {
        ...item
      }

      this.$refs.collect.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.filter-wrap {
  position: fixed;
  right: 0;
  top: 0;
  width: 70%;
  max-width: 1000px;
  z-index: 999;
  .filter-close {
    height: 52px;
    width: 38px;
    border-radius: 5px;
    background-color: #3b86ff;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 101;
    > i {
      color: #fff;
      font-size: 24px;
    }
  }
  .filter-body {
    overflow-y: auto;
    height: 100vh;
    position: relative;
    background-color: #fff;
    z-index: 100;
  }
  .exercises-list-pagination {
    text-align: center;
    &.el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
  .title {
    padding: 22px 0;
    display: flex;
    align-items: center;
    .title-type {
      width: 52px;
      height: 52px;
      border-radius: 10px;
      background-color: #ff606d;
      font-size: 20px;
      line-height: 1;
      font-weight: bold;
      color: #fff;
      word-break: break-all;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .title-type-name {
      font-size: 26px;
      color: #585d6e;
      margin-left: 12px;
    }
    .title-ai {
      width: 18px;
      height: 18px;
      border-radius: 3px;
      background-color: #ffc06a;
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }
    .title-search {
      margin-left: 55px;
      .search-input {
        max-width: 30px;
        height: 29px;
        transition: 0.3s all;

        &.active {
          max-width: 300px;

          ::v-deep .el-input__inner {
            background: #ebebf2;
          }
        }

        ::v-deep .el-input__inner {
          height: 29px;
          line-height: 29px;
          border: none;
          border-radius: 15px;
          padding-right: 0;
        }

        .el-input__icon {
          line-height: 29px;
        }

        .el-icon-search {
          font-size: 14px;
          font-weight: bold;

          &.active {
            color: #3b86ff;
          }
        }

        ::v-deep .el-input__clear {
          line-height: 31px;
        }

        .el-icon-close {
          display: none;
          font-size: 14px;
          cursor: pointer;

          &.active {
            display: inline-block;
          }

          &:hover {
            color: #fc577a;
          }
        }
      }
    }
  }
  .tag-filter {
    display: inline-block;
    padding: 4px 6px;
    background-color: #f4f4f4;
    border: 1px solid #ededed;
    border-radius: 5px;
    .tag-filter-icon {
      display: inline-block;
      padding: 0 12px;
      > i {
        color: #1f1f40;
        font-size: 15px;
      }
    }
    .filter-btn {
      display: inline-block;
      padding: 4px 12px;
      font-size: 14px;
      border-radius: 3px;
      margin: 0 4px;
      cursor: pointer;
      color: #9c9c9c;
      &:hover {
        color: #3b86ff;
      }
      &.active {
        background-color: #fff;
        color: #3b86ff;
      }
    }
  }
  .select-filter-hr {
    border-top: 1px solid #ededed;
    margin-top: 10px;
  }
  .select-filter {
    margin-top: 10px;
    ::v-deep .el-select {
      width: 120px;
      margin-right: 10px;
      .el-input {
        input {
          background-color: #f4f4f4;
          border-color: #f4f4f4;
          &::placeholder {
            color: #3a3a3a;
          }
        }
      }
    }
  }
  .subtitle {
    margin-top: 10px;
    padding: 3px 6px;
    border-radius: 5px;
    font-weight: normal;
    color: #bfbfbf;
    > b {
      font-weight: normal;
      color: #3b86ff;
    }
  }
  .filter-shadow {
    padding: 0 34px 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .list {
    padding: 0 34px;
    margin-top: 10px;
    min-height: 410px;
    li {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 5px 0;
      line-height: 20px;
      border-bottom: 1px solid #ebebf2;
      span {
        display: inline-block;
      }
    }
  }
  .name {
    flex-shrink: 0;
    max-width: 280px;
    font-size: 14px;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    &:hover {
      color: #3b86ff;
    }
  }
  .activeClass {
    font-weight: bolder;
    color: #3b86ff;
    padding-left: 14px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      border-width: 5px;
      border-color: transparent;
      border-style: solid;
      border-right-width: 0;
      border-left-color: #3b86ff;
    }
  }
  .idTag {
    margin: 5px 0;
    flex-shrink: 0;
    height: 20px;
    padding: 0 10px;
    color: #a4afb7;
    background: #ebebf2;
    border-radius: 15px;
  }
  .detailTag {
    margin: 5px;
    flex-shrink: 0;
    font-weight: 300;
    height: 20px;
    padding: 0 7px;
    color: #ffffff;
    background: #c1e6fe;
    border-radius: 15px;
    i {
      font-size: 12px;
      margin-right: 1px;
    }
  }
  .tagItem {
    height: 20px;
    margin: 5px 6px 5px 0;
    padding: 0 7px;
    border-radius: 15px;
    box-sizing: border-box;
  }
  .right-tag {
    margin-left: auto;
    flex-shrink: 0;
  }
  .collection {
    width: 44px;
    display: flex;
    justify-content: right;
    > i {
      cursor: pointer;
      color: #bfbfbf;
      &.actived {
        color: #ffc06a;
      }
    }
  }
  .tagList {
    display: flex;
    flex-wrap: wrap;
    margin-left: 12px;
  }
  .el-dialog__body {
    margin-top: -30px;
  }
  .more {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #ebebf2;
    opacity: 1;
    border-radius: 15px;
    color: #c7cade;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 34px;
    margin-right: 34px;
  }
  .tip-text {
    background-color: #fff3df;
    padding: 4px 6px;
    border-radius: 5px;
    font-size: 14px;
    color: #f8772c;
    display: inline-block;
  }
}
.filter-open-btn {
  position: fixed;
  right: -2px;
  top: 50%;
  z-index: 99;
  height: 52px;
  width: 34px;
  border-radius: 5px;
  background-color: #3b86ff;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > i {
    color: #fff;
    font-size: 24px;
    display: inline-block;
    transform: rotateZ(180deg);
  }
}
</style>

<style lang="scss">
.filter-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
.filter-slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease-in-out;
  }
  &-enter,
  &-leave-to {
    transform: translateX(100%);
  }
}
</style>
